
import { defineComponent } from "vue";
import { getToothyDownloadURL } from "@/firebase/functions";


export default defineComponent({
    name: "Installeerimine",
    setup() {
        const downloadToothy = async () => window.open(await getToothyDownloadURL(), '_blank')
        return { downloadToothy };
    },
});
